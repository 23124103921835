/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, title, noIndex }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {          
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s - ${defaultTitle}` : null}      
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {noIndex ? <meta name="robots" content="noindex" /> : null}
      
      <script type="application/ld+json">
        {`
          {
            "@context" : "http://schema.org",
            "@type" : "LocalBusiness",
            "name" : "IT Proactive",
            "openingHours": "Mo,Tu,We,Th,Fr 08:00-18:00",
            "telephone" : "(949) 543-0230",
            "address" : {
              "@type" : "PostalAddress",
              "streetAddress" : "1314 W 5th Street, 2nd Floor",
              "addressLocality" : "Santa Ana",
              "addressRegion" : "CA",
              "postalCode" : "92703"
            },
            "priceRange" : "$$$"
          }
        `}
      </script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
