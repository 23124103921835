import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import HomeHeroSectionNew from "../components/sections/home-hero-section-new"
import HomeIntroSectionUpdate from "../components/sections/home-intro-section-update"
import HomeChecklistSectionNew from "../components/sections/home-checklist-section-new"
import HomeCtaSectionNew from "../components/sections/home-cta-section-new"
import HomeDavidSection from "../components/sections/home-david-section"
import CapabilitiesSection from "../components/sections/capabilities-section"

const SantaAnaPage = () => (
  <Layout>
    <Seo
      title="Fully Managed IT Network Support Santa Ana"
      description="IT Proactive provides excellent IT Service Management and IT Support to clients in the Santa Ana, CA area."
      noIndex
    />

    <HomeHeroSectionNew 
      title="We Take a Proactive Approach in Managing Your Computer Network in Santa Ana" 
    />

    <HomeIntroSectionUpdate 
      title="We keep your network up and running with no interruptions."
      description="We are a critical resource for monitoring your network. We have 24/7 monitoring solutions, that discovers problems before you even know it! Are you looking for the Best IT Services in Santa Ana?"
    />

    <HomeChecklistSectionNew 
      title="How much profit do you lose when your computer network is down?"
      subTitle="Looking for the best IT Company?"
      description="Minimize your losses and stay one step ahead of potential disasters. We can help you with all of your IT services in Santa Ana."
    />

    <HomeDavidSection />

    <HomeCtaSectionNew 
      title="IT Management Services"
      description="IT Proactive provides excellent IT Service Management and IT Support to clients in the Santa Ana, CA area. We help our clients with IT Support in Santa Ana. We also provide Outsourced IT Services in Orange County. We are Orange County best IT Company. Give us a call today for a free estimate on your next Information Technology (IT) project and we'll be able to assist you."
    />

    <CapabilitiesSection />
  </Layout>
)

export default SantaAnaPage
